const backgrounds = [
    {
        "biome": "Desert Spires",
        "background": "background_desert.svg"
    },
    {
        "biome": "Swamp",
        "background": "background_swamp.svg"
    },
    {
        "biome": "Sandstone Valley",
        "background": "background_desert.svg"
    },
    {
        "biome": "Snowy Taiga",
        "background": "background_snow.svg"
    },
    {
        "biome": "Mirage Isles",
        "background": "background_mystical.svg"
    },
    {
        "biome": "Jungle Mountains",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Mountain Steppe",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Warm Ocean",
        "background": "background_ocean.svg"
    },
    {
        "biome": "Stony Peaks",
        "background": "background_snow.svg"
    },
    {
        "biome": "Skylands Autumn",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Snowy Badlands",
        "background": "background_snow.svg"
    },
    {
        "biome": "Deep Lukewarm Ocean",
        "background": "background_ocean.svg"
    },
    {
        "biome": "Birch Taiga",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Lavender Valley",
        "background": "background_mystical.svg"
    },
    {
        "biome": "Frozen Cliffs",
        "background": "background_snow.svg"
    },
    {
        "biome": "Caldera",
        "background": "background_night.svg"
    },
    {
        "biome": "Sakura Valley",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Deep Cold Ocean",
        "background": "background_ocean.svg"
    },
    {
        "biome": "Gravel Beach",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Arid Highlands",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Windswept Spires",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Savanna Slopes",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Grove",
        "background": "background_snow.svg"
    },
    {
        "biome": "Temperate Highlands",
        "background": "background_night.svg"
    },
    {
        "biome": "Old Growth Pine Taiga",
        "background": "background_swamp.svg"
    },
    {
        "biome": "Jagged Peaks",
        "background": "background_snow.svg"
    },
    {
        "biome": "Wintry Lowlands",
        "background": "background_snow.svg"
    },
    {
        "biome": "Stony Shore",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Desert",
        "background": "background_desert.svg"
    },
    {
        "biome": "Yosemite Cliffs",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Cold Ocean",
        "background": "background_ocean.svg"
    },
    {
        "biome": "Alpine Grove",
        "background": "background_snow.svg"
    },
    {
        "biome": "Frozen River",
        "background": "background_snow.svg"
    },
    {
        "biome": "Siberian Grove",
        "background": "background_snow.svg"
    },
    {
        "biome": "Moonlight Grove",
        "background": "background_night.svg"
    },
    {
        "biome": "Blooming Valley",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Ice Spikes",
        "background": "background_snow.svg"
    },
    {
        "biome": "Savanna Badlands",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Lush Caves",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Sparse Jungle",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Badlands",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Blooming Plateau",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Warped Mesa",
        "background": "background_mystical.svg"
    },
    {
        "biome": "Granite Cliffs",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Ancient Sands",
        "background": "background_desert.svg"
    },
    {
        "biome": "The End",
        "background": "background_night.svg"
    },
    {
        "biome": "Basalt Deltas",
        "background": "background_night.svg"
    },
    {
        "biome": "Alpha Islands Winter",
        "background": "background_snow.svg"
    },
    {
        "biome": "Snowy Maple Forest",
        "background": "background_snow.svg"
    },
    {
        "biome": "Bryce Canyon",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Dripstone Caves",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Sakura Grove",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Lush Valley",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Warm River",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Scarlet Mountains",
        "background": "background_snow.svg"
    },
    {
        "biome": "End Highlands",
        "background": "background_night.svg"
    },
    {
        "biome": "Soul Sand Valley",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Sunflower Plains",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Old Growth Spruce Taiga",
        "background": "background_swamp.svg"
    },
    {
        "biome": "Wintry Forest",
        "background": "background_snow.svg"
    },
    {
        "biome": "Windswept Savanna",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Frozen Peaks",
        "background": "background_snow.svg"
    },
    {
        "biome": "Stony Spires",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Haze Mountain",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Plains",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Skylands",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Lavender Forest",
        "background": "background_mystical.svg"
    },
    {
        "biome": "Siberian Taiga",
        "background": "background_snow.svg"
    },
    {
        "biome": "River",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Old Growth Birch Forest",
        "background": "background_swamp.svg"
    },
    {
        "biome": "Alpha Islands",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Windswept Gravelly Hills",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Snowy Slopes",
        "background": "background_snow.svg"
    },
    {
        "biome": "Painted Mountains",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Windswept Forest",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Skylands Spring",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Frozen Ocean",
        "background": "background_ocean.svg"
    },
    {
        "biome": "Volcanic Peaks",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Windswept Hills",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Wooded Badlands",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Emerald Peaks",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Beach",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Shield Clearing",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Savanna",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Snowy Beach",
        "background": "background_snow.svg"
    },
    {
        "biome": "Deep Warm Ocean",
        "background": "background_ocean.svg"
    },
    {
        "biome": "Alpine Highlands",
        "background": "background_snow.svg"
    },
    {
        "biome": "Rocky Jungle",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Flower Forest",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Gravel Desert",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Ice Marsh",
        "background": "background_snow.svg"
    },
    {
        "biome": "Jungle",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Shield",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Moonlight Valley",
        "background": "background_night.svg"
    },
    {
        "biome": "Rocky Shrubland",
        "background": "background_swamp.svg"
    },
    {
        "biome": "Cloud Forest",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Ocean",
        "background": "background_ocean.svg"
    },
    {
        "biome": "Deep Frozen Ocean",
        "background": "background_snow.svg"
    },
    {
        "biome": "Forested Highlands",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Savanna Plateau",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Hot Shrubland",
        "background": "background_swamp.svg"
    },
    {
        "biome": "Snowy Plains",
        "background": "background_snow.svg"
    },
    {
        "biome": "Yosemite Lowlands",
        "background": "background_sunset.svg"
    },
    {
        "biome": "White Mesa",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Ashen Savanna",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Desert Oasis",
        "background": "background_desert.svg"
    },
    {
        "biome": "Birch Forest",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Highlands",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Shrubland",
        "background": "background_swamp.svg"
    },
    {
        "biome": "The Void",
        "background": "background_night.svg"
    },
    {
        "biome": "Lukewarm Ocean",
        "background": "background_ocean.svg"
    },
    {
        "biome": "End Barrens",
        "background": "background_night.svg"
    },
    {
        "biome": "Taiga",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "White Cliffs",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Desert Canyon",
        "background": "background_desert.svg"
    },
    {
        "biome": "Glacial Chasm",
        "background": "background_snow.svg"
    },
    {
        "biome": "Nether Wastes",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Brushland",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Meadow",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Red Oasis",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Skylands Summer",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Amethyst Rainforest",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Dark Forest",
        "background": "background_swamp.svg"
    },
    {
        "biome": "Bamboo Jungle",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Small End Islands",
        "background": "background_night.svg"
    },
    {
        "biome": "Amethyst Canyon",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Skylands Winter",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Mushroom Fields",
        "background": "background_shroom.svg"
    },
    {
        "biome": "Cold Shrubland",
        "background": "background_snow.svg"
    },
    {
        "biome": "Yellowstone",
        "background": "background_sunset.svg"
    },
    {
        "biome": "Warped Forest",
        "background": "background_mystical.svg"
    },
    {
        "biome": "Rocky Mountains",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Fractured Savanna",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Steppe",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Tropical Jungle",
        "background": "background_hazy.svg"
    },
    {
        "biome": "Deep Ocean",
        "background": "background_ocean.svg"
    },
    {
        "biome": "Basalt Cliffs",
        "background": "background_night.svg"
    },
    {
        "biome": "Forest",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Orchid Swamp",
        "background": "background_swamp.svg"
    },
    {
        "biome": "Eroded Badlands",
        "background": "background_sunrise.svg"
    },
    {
        "biome": "Valley Clearing",
        "background": "background_sunny.svg"
    },
    {
        "biome": "Snowy Shield",
        "background": "background_snow.svg"
    },
    {
        "biome": "Volcanic Crater",
        "background": "background_sunset.svg"
    }
];

export default backgrounds;