import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, addDoc, doc, setDoc, getDoc, getDocs, query, where } from "firebase/firestore";
import { getRemoteConfig, fetchAndActivate, activate, fetchConfig, getBoolean, getString, getValue } from "firebase/remote-config";

const isBrowser = () => typeof window !== "undefined";

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

let remoteConfig = undefined;

// if (isBrowser()) {
//     // Initialize Analytics
//     const analytics = getAnalytics(app);

//     // Initialize Remote Config and get a reference to the service
//     remoteConfig = getRemoteConfig(app);
//     remoteConfig.settings.minimumFetchIntervalMillis = 60000; //3600000
// }

export { app, db, auth, signInAnonymously, onAuthStateChanged, collection, addDoc, doc, setDoc, getDoc, remoteConfig, where, fetchAndActivate, getBoolean, getValue, isBrowser, getString, query, getDocs }