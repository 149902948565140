import WebGL from 'three/examples/jsm/capabilities/WebGL.js';
import { Viewer } from './viewer.js';
import { Utils } from './utils.js';
import config from './config';
import queryString from 'query-string';
import backgrounds from './backgrounds.js';
import { auth, signInAnonymously, getDocs, db, query, collection, where } from "./firebase.js";

// import { SimpleDropzone } from 'simple-dropzone';
// import { ValidationController } from './validation-controller.js';

if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
  this.utils.error('The File APIs are not fully supported in this browser.');
} else if (!WebGL.isWebGLAvailable()) {
  this.utils.error('WebGL is not supported in this browser.');
}

class App {

  /**
   * @param  {Element} el
   * @param  {Location} location
   */
  constructor(el, location) {

    const hash = location.hash ? queryString.parse(location.hash) : {};

    const params = new URLSearchParams(window.location.search);
    let chunkId = params.has('id') ? params.get('id') : 0;

    this.options = {
      kiosk: Boolean(hash.kiosk),
      model: hash.model || '',
      preset: hash.preset || '',
      cameraPosition: hash.cameraPosition
        ? hash.cameraPosition.split(',').map(Number)
        : null
    };

    this.utils = new Utils();
    this.el = el;
    this.viewer = null;
    this.viewerEl = null;
    this.dropEl = el.querySelector('.dropzone');

    this.utils.log(`Chunk id ${chunkId}`);

    if (chunkId < 1 || chunkId > 10000) {
      this.utils.logError("Invalid Chunk Id");

      // Test CF redirection
      //chunkId = 8;
      this.utils.hideLoader();
      return;
    }

    // Sign In
    this.signIn()
      .then(async (user) => {
        this.utils.log(`Signed`);

        // Get biome for chunk id
        let biome = undefined;
        if (config.local) {
          biome = "Savanna Plateau";
        }
        else {
          biome = await this.getChunkInfo(chunkId);
        }

        this.utils.log(`most_common_biome ${biome}`);

        if (biome == undefined) {
          this.utils.logError("ChunkId does not exist");
          this.utils.hideLoader();
          return;
        }

        // Get background for biome
        const bs = backgrounds.filter(i => i.biome === biome);
        if (bs.length > 0) {
          this.background = bs[0].background;
          this.utils.log(`background ${this.background}`);
        }

        this.view(chunkId);
      })
      .catch((error) => {
        this.utils.logError(error)
      });
  }

  signIn() {
    return new Promise((resolve, reject) => {

      signInAnonymously(auth)
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          const errorMessage = error.message;
          reject(error);
        });
    });
  }

  async getChunkInfo(uuid) {

    try {
      const chunksRef = collection(db, "chunks");
      const q = query(chunksRef, where("_id", "==", parseInt(uuid)));

      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        const biome = data["most_common_biome"];

        return biome;
      }
    }
    catch (err) {
      this.utils.logError(`Error ${err}`);
      return "";
    }
  }

  /**
   * Sets up the view manager.
   * @return {Viewer}
   */
  createViewer() {
    this.viewerEl = document.createElement('div');
    this.viewerEl.classList.add('viewer');
    this.dropEl.innerHTML = '';
    this.dropEl.appendChild(this.viewerEl);
    this.viewer = new Viewer(this.viewerEl, this.options, this.background);
    return this.viewer;
  }

  /**
   * Passes a model to the viewer, given file and resources.
   * @param  {File|string} rootFile
   * @param  {string} rootPath
   * @param  {Map<string, File>} fileMap
   */
  view(chunkId) {

    if (this.viewer) this.viewer.clear();

    const viewer = this.viewer || this.createViewer();

    if (config.local) {
      viewer
        .load(`./assets/models/chunknft.glb`)
        .catch((e) => this.onError(e))
        .then((gltf) => {
        });
    }
    else {
      if (chunkId > 0) {
        viewer
          .load(`${config.api.assets}/models/glb/${chunkId}.glb`)
          .catch((e) => this.onError(e))
          .then((gltf) => {
          });
      }
      else {
        // Not found
      }
    }
  }

  /**
   * @param  {Error} error
   */
  onError(error) {
    let message = (error || {}).message || error.toString();
    if (message.match(/ProgressEvent/)) {
      message = 'Unable to retrieve this file. Check JS console and browser network tab.';
    } else if (message.match(/Unexpected token/)) {
      message = `Unable to parse file content. Verify that this file is valid. Error: "${message}"`;
    } else if (error && error.target && error.target instanceof Image) {
      message = 'Missing texture: ' + error.target.src.split('/').pop();
    }
    window.alert(message);
    this.utils.error(error);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const app = new App(document.body, location);
});
