import config from './config.js';

export class Utils {

  constructor() {

  }

  showLoader() {
    var loader = document.getElementById("loader");
    loader.style.display = 'block';

  }

  hideLoader() {
    var loader = document.getElementById("loader");
    loader.style.display = 'none';
  }

  log(message) {
    if (config.debug) {
      console.log(message);
    }
  }

  logError(message) {
    if (config.debug) {
      console.error(message);
    }
  }
}